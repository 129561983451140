// Colors:

export const colorBlack = '#000000';
export const colorWhite = '#FFFFFF';
export const colorRed = '#D02239';
export const colorGreyDark = '#494F52';
export const colorGrey = '#E4E4E4';
export const colorGreyLight = '#C7C6C6';
export const colorGreyLighter = '#F2F2F2';
export const colorGreen = '#207600';

export const colorPrimary = colorRed;
export const colorBorderGrey = colorGreyLight;
export const colorTextGrey = colorGrey;
export const colorSuccess = colorGreen;
