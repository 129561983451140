import React from 'react';
import styled from 'styled-components';

import { colorPrimary, colorWhite } from '../styles/Colors';

const Loading = ({ inline }) => <LoadingSpinner inline={inline} />;

const LoadingSpinner = styled.div`
  display: inline-block;
  width: 80px;
  height: 80px;
  position: ${(props) => (props.inline ? 'static' : 'absolute')};
  top: 50%;
  left: 50%;
  transform: ${(props) => (props.inline ? `none` : `translate(-50%,-50%)`)};
  &:after {
    content: ' ';
    display: block;
    width: ${(props) => (props.inline ? '22px' : '64px')};
    height: ${(props) => (props.inline ? '22px' : '64px')};
    margin: 8px;
    border-radius: 50%;
    border: 4px solid ${colorWhite};
    border-color: transparent ${colorPrimary} transparent ${colorPrimary};
    animation: spinner 1.2s linear infinite;
  }

  @keyframes spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export default Loading;
