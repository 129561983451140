import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-polyglot';

import { headingFont } from '../../../styles/Typography';
import { colorWhite } from '../../../styles/Colors';

import ArrowLeft from '../../../assets/images/arrow-left.svg';
import ArrowRight from '../../../assets/images/arrow-right.svg';

const ProductListToggle = ({ showProductList, handleClick, products }) => {
  const t = useTranslate();
  return (
    <Wrapper>
      <ControlsWrapper className="fl jc-sb ai-c">
        {!showProductList ? (
          <Fragment>
            <ProductCount>
              {products.length} {products.length === 1 ? t('item') : t('items')}
            </ProductCount>
            <ProductLink onClick={handleClick} className="fl ai-c" href="#">
              {t('showOrderDetails')}
              <Icon ml src={ArrowRight} alt="" />
            </ProductLink>
          </Fragment>
        ) : (
          <ProductLink
            maxWidth
            onClick={handleClick}
            className="fl ai-c"
            href="#">
            <Icon mr src={ArrowLeft} alt="" />
            {t('goBack')}
          </ProductLink>
        )}
      </ControlsWrapper>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0.5rem;

  @media (orientation: landscape) {
    padding: 0;
    flex: auto;
  }

  @media screen and (max-height: 290px) {
    padding: 4px 12px;
  }
`;

const ControlsWrapper = styled.ul`
  background-color: #494f52;
  padding: 0 0.875rem;
  @media (orientation: landscape) {
    max-width: 320px;
    margin-left: auto;
  }
`;

const ProductCount = styled.p`
  font-family: ${headingFont};
  font-size: 1.625rem;
  font-weight: 300;
  color: ${colorWhite};
  @media screen and (max-width: 568px) and (orientation: landscape) {
    font-size: 21px;
  }
`;

const ProductLink = styled.a`
  font-family: ${headingFont};
  font-size: 1rem;
  font-weight: 700;
  text-transform: uppercase;
  color: ${colorWhite};
  height: 40px;
  min-width: 170px;
  width: ${(props) => (props.maxWidth ? '100%' : null)};
`;

const Icon = styled.img`
  margin-right: ${(props) => (props.mr ? '0.40rem' : 0)};
  margin-left: ${(props) => (props.ml ? '0.40rem' : 0)};
`;

export default ProductListToggle;
