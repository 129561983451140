import React, { useState, useContext, useRef } from 'react';

export const GlobalData = React.createContext();
export const useGlobalData = () => useContext(GlobalData);

export const GlobalDataProvider = ({ children }) => {
  const [captureSubmitted, setCaptureSubmitted] = useState(false);
  const [currentSig, setCurrentSig] = useState(false);
  const [error, setError] = useState(false);
  const [logo, setLogo] = useState(false);
  const [deviceInfo, setDeviceInfo] = useState(null);
  const sigPad = useRef(null);

  return (
    <GlobalData.Provider
      value={{
        captureSubmitted,
        setCaptureSubmitted,
        sigPad,
        currentSig,
        setCurrentSig,
        error,
        setError,
        logo,
        setLogo,
        deviceInfo,
        setDeviceInfo,
      }}>
      {children}
    </GlobalData.Provider>
  );
};
