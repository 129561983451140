const es = {
  send: 'Enviar',
  clear: 'Resetear',
  signForGoods: 'Firma de entrega de mercancia',
  items: 'Artículos',
  item: 'Articulo',
  showOrderDetails: 'Mostrar detalles del pedido',
  accountNumber: 'Número de cuenta',
  copyright:
    'La captura de firma en este formulario confirma la aprobación del cliente de la transacción. Esta firma se puede usar en otros documentos relacionados con este número de documento solamente.',
  goBack: 'Volver',
  done: 'Completado',
  reference: 'referencia',
  confirmationText:
    'Recibirá una confirmación por e-mail de esta entrega para su archivo',
  thankYouForUsingCEF: 'Gracias por confiar en CEF',
  error404: 'este enlace no está disponible',
  error404text: '¿Es posible que este pedido ya haya sido firmado?',
  error500: 'Este servicio no está disponible actualmente',
  error500text: 'Vuelva a intentarlo más tarde o firme por otros medios.',
};

export default es;
