import React, { Fragment, useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import styled from 'styled-components';
import { browserName, browserVersion, deviceDetect } from 'react-device-detect';

import SignatureHeader from '../components/SignatureCapture/SignatureHeader';
import SignaturePad from '../components/SignatureCapture/SignaturePad';
import SignatureProductList from '../components/SignatureCapture/SignatureProductList/SignatureProductList';
import ProductListToggle from '../components/SignatureCapture/SignatureProductList/ProductListToggle';
import Loading from '../components/Loading';
import ErrorComponent from '../components/Error';

import { useGlobalData } from '../context/globalDataProvider';

import cefLogo from '../assets/images/cef-logo.svg';
import esmLogo from '../assets/images/esm-logo.svg';
import cefLogoAU from '../assets/images/cef-logo-au.svg';
import esmFavicon from '../assets/images/esm-favicon.png';
import cefFavicon from '../assets/images/favicon.ico';

const SignaturePage = () => {
  const [showProductList, setShowProductList] = useState(false);
  const [loading, isLoading] = useState(true);
  const [data, setData] = useState(null);
  const [pageTitle, setPageTitle] = useState('Welcome to CEF - ePOC');

  const {
    sigPad,
    currentSig,
    error,
    setError,
    setLogo,
    setDeviceInfo,
  } = useGlobalData();

  const { country, ref } = useParams();

  useEffect(() => {
    const { model, vendor, os, osVersion } = deviceDetect();

    setDeviceInfo({
      device: {
        model,
        vendor,
      },
      os: {
        os,
        osVersion,
      },
      browser: {
        browserName,
        browserVersion,
      },
    });

    if (country === 'je' || country === 'gg') {
      setLogo(esmLogo);
      setPageTitle('ESM - Sign for goods');
    } else if (country === 'au') {
      setLogo(cefLogoAU);
      setPageTitle('CES - Sign for goods');
    } else if (country === 'es') {
      setLogo(cefLogo);
      setPageTitle('CEF - Firma de entrega de mercancia');
    } else {
      setLogo(cefLogo);
      setPageTitle('CEF - Sign for goods');
    }
  }, [country]);

  useEffect(() => {
    const { REACT_APP_BASE_URL, REACT_APP_API_KEY } = process.env;
    axios
      .get(`${REACT_APP_BASE_URL}/${ref}`, {
        headers: { 'x-api-key': REACT_APP_API_KEY },
      })
      .then(({ data }) => {
        setData(data);
        isLoading(false);
      })
      .catch(({ response }) => {
        if (response && response.status) {
          setError(404);
        } else {
          setError(500);
        }
        isLoading(false);
      });
  }, [ref]);

  useEffect(() => {
    if (!showProductList && currentSig) {
      if (!sigPad.current === null) {
        sigPad.current.fromData(currentSig);
      }
    }
  }, [showProductList]);

  const handleShowProducts = () => {
    setShowProductList(!showProductList);
  };

  if (loading) {
    return <Loading />;
  } else if (error) {
    return <ErrorComponent errorCode={error} />;
  } else {
    const { branchprefix, documentNumber, accountName, lines, account } = data;
    return (
      <Fragment>
        <Helmet>
          <title>{pageTitle}</title>
          {(!country && country === 'je') || country === 'gg' ? (
            <link rel="shortcut icon" type="image/x-icon" href={esmFavicon} />
          ) : (
            <link rel="shortcut icon" type="image/x-icon" href={cefFavicon} />
          )}
        </Helmet>
        <SignatureHeader
          headerInfo={{ accountName, account }}
          products={lines}
          showProductList={showProductList}
          handleClick={handleShowProducts}
        />
        <ProductListWrapper>
          <ProductListToggle
            products={lines}
            showProductList={showProductList}
            handleClick={handleShowProducts}
          />
        </ProductListWrapper>

        {showProductList && (
          <SignatureProductList
            products={lines}
            showProductList={showProductList}
          />
        )}

        {!showProductList && (
          <SignaturePad data={{ branchprefix, documentNumber }} />
        )}
      </Fragment>
    );
  }
};

const ProductListWrapper = styled.div`
  display: block;
  @media (orientation: landscape) {
    display: none;
  }
`;

export default SignaturePage;
