import React from 'react';
import ReactDOM from 'react-dom';
import { I18n } from 'react-polyglot';
import './index.css';
import App from './App';
import en from './locales/en';
import es from './locales/es';
import * as serviceWorker from './serviceWorker';

const locale = window.location.pathname.split('/')[1] || 'en';
const translations = { en, es };

ReactDOM.render(
  <I18n locale={locale} messages={translations[locale] || en}>
    <App />
  </I18n>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
