const en = {
  send: 'Send',
  clear: 'clear',
  signForGoods: 'Sign for goods',
  items: 'Items',
  item: 'Item',
  showOrderDetails: 'Show order details',
  accountNumber: 'Account Number',
  copyright:
    'Signature Capture on this form confirms customer approval of the transaction. This signature may be used on other documents pertaining to this document number only.',
  goBack: 'Go back',
  done: 'done',
  reference: 'Reference',
  confirmationText:
    'You will receive an email confirmation of this collection for your reference.',
  thankYouForUsingCEF: 'Thank you for using CEF',
  error404: 'this link is unavailable',
  error404text: 'This order may have already been signed for?',
  error500: 'This service is Currently Unavailable',
  error500text: 'Please try again later or sign for by other means.',
};

export default en;
