import React, { useState, useEffect } from 'react';
import SignatureCanvas from 'react-signature-pad-wrapper';
import styled from 'styled-components';
import axios from 'axios';
import { useTranslate } from 'react-polyglot';
import { useParams } from 'react-router-dom';

import { Button } from '../../styles/Button';
import { H2, P, headingFont } from '../../styles/Typography';
import {
  colorWhite,
  colorGrey,
  colorGreyLighter,
  colorRed,
  colorSuccess,
  colorGreyDark,
} from '../../styles/Colors';

import Loading from '../Loading';

import HandIcon from '../../assets/images/hand.svg';
import SentIcon from '../../assets/images/sent-icon.svg';

import { useGlobalData } from '../../context/globalDataProvider';

const SignaturePad = ({ data }) => {
  const {
    captureSubmitted,
    setCaptureSubmitted,
    sigPad,
    setCurrentSig,
    currentSig,
    setError,
    deviceInfo,
  } = useGlobalData();

  const [disabled, setDisabled] = useState(!currentSig);
  const [loading, isLoading] = useState(false);
  const { branchprefix, documentNumber } = data;
  const t = useTranslate();

  const { ref } = useParams();

  useEffect(() => {
    if (sigPad.current && currentSig) {
      sigPad.current.fromData(currentSig);
    }
  }, []);

  const handleOnEnd = () => {
    setDisabled(false);
    setCurrentSig(sigPad.current.toData());
  };

  const handleSubmit = () => {
    const { REACT_APP_BASE_URL, REACT_APP_API_KEY } = process.env;
    const postData = {
      signedDate: new Date().toISOString(),
      SIG: sigPad.current.toDataURL('image/jpeg').split(',')[1],
      deviceInfo,
    };

    isLoading(true);
    setDisabled(true);

    axios
      .post(`${REACT_APP_BASE_URL}/${ref}`, postData, {
        headers: { 'x-api-key': REACT_APP_API_KEY },
      })
      .then(() => {
        setCaptureSubmitted(true);
        isLoading(false);
      })
      .catch(({ response }) => {
        if (response && response.status) {
          setError(404);
        } else {
          setError(500);
        }
        isLoading(false);
      });
  };

  return (
    <Wrapper>
      <HeaderH2 submitted={captureSubmitted} className="fl ai-c">
        <Icon src={captureSubmitted ? SentIcon : HandIcon} alt="" />
        {captureSubmitted ? t('done') : t('signForGoods')}
      </HeaderH2>
      {!captureSubmitted ? (
        <SignatureCanvas
          canvasProps={{ className: 'canvas' }}
          ref={sigPad}
          redrawOnResize={true}
          options={{ onEnd: handleOnEnd, backgroundColor: 'rgb(255,255,255)' }}
        />
      ) : (
        <EmptyCanvas className="canvas fl fl-col jc-c">
          <ConfirmationWrapper>
            <EmptyCanvasLabel>{t('reference')}</EmptyCanvasLabel>
            <EmptyCanvasRef>
              {branchprefix}/{documentNumber}
            </EmptyCanvasRef>
          </ConfirmationWrapper>
          <ConfirmationText>{t('confirmationText')}</ConfirmationText>
        </EmptyCanvas>
      )}
      <CopyrightP>{t('copyright')}</CopyrightP>
      {!captureSubmitted ? (
        <ButtonWrapper className="fl">
          <ClearButton
            disabled={disabled}
            small
            onClick={() => {
              setDisabled(true);
              sigPad.current.clear();
              setCurrentSig(null);
            }}>
            {t('clear')}
          </ClearButton>
          <SendButton onClick={handleSubmit} disabled={disabled}>
            {t('send')}
            {loading && <Loading inline={true} />}
          </SendButton>
        </ButtonWrapper>
      ) : (
        <ThankYouText>{t('thankYouForUsingCEF')}</ThankYouText>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  background-color: ${colorGreyLighter};
  padding: 0.75rem 1rem;
  position: relative;
`;

const HeaderH2 = styled(H2)`
  background-color: ${colorWhite};
  padding: 1rem 1rem 0 1rem;
  color: ${(props) => (props.submitted ? colorSuccess : null)};

  @media (orientation: landscape) {
    position: absolute;
    background-color: transparent;
  }
`;

const CopyrightP = styled(P)`
  font-size: 0.6875rem;
  color: ${colorGreyDark};
  margin: 4px 0;
`;

const ButtonWrapper = styled.div`
  margin-top: 1rem;
  @media (orientation: landscape) {
    margin-top: 0.5rem;
  }
`;

const ClearButton = styled(Button)`
  width: 30%;
  border: 1px solid ${colorRed};
  color: ${colorRed};
  background-color: ${colorWhite};
  &:disabled {
    color: ${colorGrey};
    border-color: ${colorGrey};
  }

  @media (orientation: landscape) {
    height: 44px;
  }
`;

const SendButton = styled(Button)`
  background-color: ${colorSuccess};
  color: ${colorWhite};
  width: 70%;
  margin-left: 1rem;
  &:disabled,
  &:focus {
    background-color: ${colorGrey};
  }

  @media (orientation: landscape) {
    height: 44px;
  }
`;

const Icon = styled.img`
  margin-right: 1rem;
`;

const EmptyCanvas = styled.div`
  background-color: ${colorWhite};
  height: 150px;
  padding: 0 20px;
  @media screen and (min-width: 360px) {
    height: 350px;
    padding: 0 40px;
  }

  @media only screen and (min-width: 360px) and (max-height: 630px) {
    height: 260px;
  }

  @media screen and (min-width: 400px) {
    padding: 0 60px;
  }

  @media (orientation: landscape) {
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    height: 200px;
    padding: 0 20px;
  }
`;

const EmptyCanvasLabel = styled(P)`
  text-transform: uppercase;
  font-family: ${headingFont};
  font-size: 1.125rem;
  font-weight: 700;
  @media (orientation: landscape) {
    margin-top: 4rem;
  }
`;

const EmptyCanvasRef = styled(P)`
  text-transform: uppercase;
  font-family: ${headingFont};
  font-size: 2.5rem;
  font-weight: 300;
  margin: 1rem 0;
  @media (orientation: landscape) {
    font-size: 2.5rem;
    margin: 0.5rem 0;
  }
`;

const ConfirmationText = styled(P)`
  font-weight: 300;
  @media (orientation: landscape) {
    max-width: 50%;
    margin-left: 2rem;
    margin-top: 4rem;
  }
`;

const ConfirmationWrapper = styled.div`
  @media (orientation: landscape) {
    display: flex;
    flex-direction: column;
  }
`;

const ThankYouText = styled(H2)`
  margin: 1.625rem 0 1rem;
  color: #7f7f7f;
  text-align: center;
  font-size: 1.5rem;
`;

export default SignaturePad;
