import React, { Fragment } from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-polyglot';

import ErrorIcon from '../assets/images/error.svg';

import { H1, P } from '../styles/Typography';
import { colorGreyDark } from '../styles/Colors';

import { useGlobalData } from '../context/globalDataProvider';

const ErrorComponent = ({ errorCode }) => {
  const t = useTranslate();
  const { logo } = useGlobalData();
  return (
    <Fragment>
      <CefLogoImg src={logo} alt="" />
      <ErrorWrapper className="fl jc-c fl-col">
        <ErrorImg src={ErrorIcon} alt="" />
        <ErrorH1>{t(`error${errorCode}`)}</ErrorH1>
        <ErrorP>{t(`error${errorCode}text`)}</ErrorP>
      </ErrorWrapper>
    </Fragment>
  );
};

const ErrorImg = styled.img`
  align-self: flex-start;
`;

const ErrorWrapper = styled.div`
  height: 100vh;
  max-width: 230px;
  margin: 0 auto;
  @media (orientation: landscape) {
    max-width: 500px;
  }
`;

const CefLogoImg = styled.img`
  width: 96px;
  height: 30px;
  position: absolute;
  right: 1rem;
  top: 1rem;
`;

const ErrorH1 = styled(H1)`
  font-size: 2.375rem;
  color: ${colorGreyDark};
  margin: 1rem 0;
`;

const ErrorP = styled(P)`
  font-size: 1.375rem;
  font-weight: 300;
  color: ${colorGreyDark};
`;

export default ErrorComponent;
