import React, { Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { translate } from 'react-polyglot';
import * as rax from 'retry-axios';
import { polyfill } from 'es6-promise';

import SignaturePage from './pages/SignaturePage';
import { GlobalDataProvider } from './context/globalDataProvider';

import './assets/css/reset.css';
import './assets/css/index.css';
import './App.css';

polyfill();
rax.attach();

function App() {
  return (
    <GlobalDataProvider>
      <Fragment>
        <Router>
          <Switch>
            <Route path="/:country/:ref" exact component={SignaturePage} />
          </Switch>
        </Router>
      </Fragment>
    </GlobalDataProvider>
  );
}

export default translate()(App);
