import React from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-polyglot';

import { headingFont } from '../../../styles/Typography';
import { colorWhite } from '../../../styles/Colors';

import SignatureProductListItem from './SignatureProductListItem';

const SignatureProductList = ({ showProductList, products }) => {
  const t = useTranslate();

  return (
    <Wrapper>
      {products.length &&
        showProductList &&
        products.map(
          ({ identifier, description, quantity, per, unitofmeasure }) => (
            <SignatureProductListItem
              key={identifier}
              product={{
                identifier,
                description,
                quantity,
                per,
                unitofmeasure,
              }}
            />
          ),
        )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0.5rem;

  @media (orientation: landscape) {
    padding: 8px 12px;
  }

  @media screen and (max-height: 290px) {
    padding: 4px 12px;
  }
`;

export default SignatureProductList;
