import React from 'react';
import styled from 'styled-components';

import { P } from '../../../styles/Typography';
import { colorGreyDark, colorBlack } from '../../../styles/Colors';

const SignatureProductListItem = ({ product }) => {
  const { identifier, description, quantity, per, unitofmeasure } = product;
  return (
    <ProductWrapper className="fl jc-sb">
      <FieldWrapper>
        <Description>{description}</Description>
        <Identifier>{identifier}</Identifier>
      </FieldWrapper>
      <Quantity className="fl fl-col jc-sb">
        <span>x{quantity}</span>
        <span>
          {per} {unitofmeasure}
        </span>
      </Quantity>
    </ProductWrapper>
  );
};

const ProductWrapper = styled.div`
  padding: 1.125rem 0;
  border-bottom: 1px solid #a8a8a8;
  &:first-of-type {
    margin-top: 0.5rem;
  }
`;

const FieldWrapper = styled.div`
  flex: 50%;
`;

const Description = styled(P)`
  color: ${colorGreyDark};
  font-size: 0.875rem;
  margin-bottom: 0.625rem;
  margin-left: 0.875rem;
  width: 75%;
`;

const Identifier = styled(P)`
  color: ${colorBlack};
  font-size: 0.6875rem;
  font-weight: 700;
  margin-left: 0.875rem;
  width: 75%;
`;

const Quantity = styled(P)`
  color: ${colorGreyDark};
  font-size: 0.875rem;
  font-weight: 700;
  margin-right: 0.5rem;
  align-items: flex-end;
`;

export default SignatureProductListItem;
