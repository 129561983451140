import React from 'react';
import styled from 'styled-components';
import { useTranslate } from 'react-polyglot';

import { colorBorderGrey, colorBlack } from '../../styles/Colors';
import { H1, P, fontSizeTiny } from '../../styles/Typography';
import { useGlobalData } from '../../context/globalDataProvider';
import ProductListToggle from '../../components/SignatureCapture/SignatureProductList/ProductListToggle';

const SignatureHeader = ({
  headerInfo,
  products,
  showProductList,
  handleClick,
}) => {
  const t = useTranslate();
  const { logo } = useGlobalData();
  const { accountName, account } = headerInfo;
  return (
    <Wrapper className="fl jc-sb">
      <HeaderWrapper className="fl">
        <HeaderInfoWrapper className="fl fw-w">
          <HeaderH1>{accountName}</HeaderH1>
          <HeaderP>
            {t('accountNumber')}
            <HeaderSpan> {account}</HeaderSpan>
          </HeaderP>
        </HeaderInfoWrapper>
        <ProductListWrapper>
          <ProductListToggle
            products={products}
            showProductList={showProductList}
            handleClick={handleClick}
          />
        </ProductListWrapper>
      </HeaderWrapper>
      <CefLogo src={logo} alt="" />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  border-bottom: 1px solid ${colorBorderGrey};
  padding: 0.5rem;

  @media (orientation: landscape) {
    padding: 6px 16px;
    justify-content: flex-start;
    align-items: center;
  }

  @media screen and (max-height: 290px) {
    padding: 4px 12px;
  }
`;

const HeaderWrapper = styled.div`
  @media (orientation: landscape) {
    order: 2;
    margin-left: 1rem;
    align-items: center;
    flex: auto;
  }
`;

const HeaderInfoWrapper = styled.div`
  max-width: 160px;
`;

const ProductListWrapper = styled.div`
  display: none;
  @media (orientation: landscape) {
    display: block;
    flex: auto;
  }
`;

const HeaderH1 = styled(H1)`
  flex: 100%;
`;

const CefLogo = styled.img`
  width: 96px;
  height: 40px;
`;

const HeaderP = styled(P)`
  font-size: ${fontSizeTiny}px;
  font-weight: 300;
`;

const HeaderSpan = styled.span`
  font-weight: normal;
  color: ${colorBlack};
`;

export default SignatureHeader;
