import styled from 'styled-components';
import { colorBlack, colorGreyDark } from './Colors';

// Typography
export const fontFallback = `"Helvetica", "Arial", sans-serif`;
export const headingFont = `"Roboto Condensed", ${fontFallback}`;
export const bodyFont = `"Roboto", ${fontFallback}`;

// Font Size
export const fontSizeTiny = 12;
export const fontSizeStandard = 16;
export const fontSizeMedium = 18;

// Headings
export const H1 = styled.h1`
  font-family: ${headingFont};
  font-size: ${fontSizeMedium}px;
  font-weight: 700;
  color: ${colorBlack};
  text-transform: uppercase;
`;

export const H2 = styled(H1)`
  color: ${colorGreyDark};
`;

export const P = styled.p`
  font-family: ${bodyFont};
  color: ${colorGreyDark};
`;
