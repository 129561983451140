import styled from 'styled-components';
import { bodyFont } from './Typography';

export const Button = styled.button`
  height: 50px;
  font-family: ${bodyFont};
  font-size: ${(props) => (props.small ? '1rem' : '1.175rem')};
  text-transform: uppercase;
  font-weight: ${(props) => (props.small ? 500 : 700)};
  outline: 0;
`;
